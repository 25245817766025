body.dark-mode {
    background-color: #10171d;
    color: #c7e5f7;

    .navbar {
        background-color: #10171D;
    }

    .nav-item {
        color: #c7e5f7;
    }

    .logo-font {
        color: #c7e5f7;
    }

    .MuiAppBar-colorPrimary {
        color: #c7e5f7 !important;
        background-color: #23282c !important;
    }

    .MuiPaper-root {
        background-color: #23282c !important;
    }

    .makeStyles-link-1 {
        color: #c7e5f7 !important;
    }

    .jss1 {
        color: #c7e5f7 !important;
    }

    .logo-font-1,
    .logo-font-2,
    .logo-seperator {
        color: #c7e5f7;
    }

    .logo-font-1 {
        border-right: 1px solid #c7e5f7;
    }
}

body.dark-mode .content .footer code {
    background-color: #1b2938;
}

body.dark-mode .content a {
    color: #ee09;
}

body.dark-mode .content button {
    color: #ee09;
}