// margin right
.util-margin-right-1 {
    margin-right: 1em;
}

.util-margin-right-1_5 {
    margin-right: 1.5em;
}

.util-margin-right-2 {
    margin-right: 2em;
}

.util-margin-right-3 {
    margin-right: 3em;
}

.util-margin-right-4 {
    margin-right: 4em;
}

// margin left
.util-margin-left-0_5 {
    margin-left: 0.5em;
}

.util-margin-left-1 {
    margin-left: 1em;
}

.util-margin-left-1_5 {
    margin-left: 1.5em;
}

.util-margin-left-2 {
    margin-left: 2em;
}

.util-margin-left-3 {
    margin-left: 3em;
}

.util-margin-left-4 {
    margin-left: 4em;
}

.util-margin-left-8 {
    margin-left: 20em;
}

.util-nav-container-left {
    margin-left: 300px;
}

.util-nav-container-right {
    margin-right: 300px;
}

.container {
    margin-right: 300px;
    margin-left: 300px;
}