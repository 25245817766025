
$color1: #294f65;

body.light-mode {
    background-color: #fff;
    color: $color1;

    .logo-font {
        color: $color1;
    }

    .nav-item {
        color: $color1;
    }

    .navbar {
        background-color: #fff;
    }

    .MuiAppBar-colorPrimary {
        color: $color1 !important;
        background-color: #fff !important;
    }

    .makeStyles-icon-2 {
        color: $color1 !important;
    }

    .jss2 {
        color: $color1 !important;
    }

    .logo-font-1,
    .logo-font-2,
    .logo-seperator {
        color: $color1;
    }

    .logo-font-1 {
        border-right: 1px solid $color1;
    }
}