@use "./utils";
@use "./light-mode";
@use "./dark-mode";
@import url("https://use.typekit.net/wub4uxm.css");

*{
  transition: background-color 0.3s ease;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

#root .row.header {
  flex: 0 1 auto;
}

#root .row.content {
  flex: 1 1 auto;
}

#root .row.footer {
  flex: 0 1 40px;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.nav-drawer {
  display: flex;
  align-items: center;

  .logo {
    margin-right: auto;
  }
}

.nav-container {
  .logo-link {
    text-decoration: none;

    .logo-container-2 {

      line-height: 2em;
      height: 100%;
      display: flex;
      align-items: center;

      .logo-font-1,
      .logo-font-2 {
        font-family: flood-std, sans-serif;
        font-style: normal;
        height: 100%;
      }

      .logo-font-1 {
        font-weight: 400;
        padding: 0.2em;
      }

      .logo-font-2 {
        font-weight: 100;
        letter-spacing: 16px;
      }
    }

  }
}



.nav-drawer {
  .logo-link {
    text-decoration: none;

    .logo-container-2 {

      line-height: 2em;
      height: 100%;
      display: flex;
      align-items: center;

      .logo-font-1,
      .logo-font-2 {
        font-family: flood-std, sans-serif;
        font-style: normal;
        height: 100%;
      }

      .logo-font-1 {
        font-weight: 400;
        font-size: 24px;
        padding: 0.3em;
      }

      .logo-font-2 {
        font-weight: 100;
        font-size: 8px;
        letter-spacing: 16px;
      }
    }

  }

  .logo {
    margin-right: auto;
  }

  .drawer-toggle {
    display: flex;
    align-items: center;
    margin-right: 1em;
  }

  padding-left: 12px;
}

.nav-container {
  position: relative;
  height: 4.5em;

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: inherit;
  }

  .nav-item {
    text-decoration: none;
    margin-left: 3em;
    font-size: 24px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-img {
    height: 3em;
  }

  .logo-font-1 {
    font-size: 64px;
  }

  .logo-font-2 {
    font-size: 11px;
  }

  .logo-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: inherit;
  }
}

.toggle-btn {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1.25em;
}

.container {
  margin: 0 300px;
  margin-top: 60px;
  max-width: 600px;
  font-size: 20px;
  line-height: 1.3em;

  h1 {
    font-size: 3rem;
  }

  img {
    width: 100%;
  }

  code {
    background-color: #cbe9ff;
    border-radius: 2px;
    font-size: 1em;
    padding: 0 5px;
  }

  a {
    text-decoration: none;
    color: #006688;
  }

  button {
    color: #006688;
    background-color: transparent;
    border: none;
    font-size: 1em;
    padding: 0;
  }
}

.row.content {
  display: flex;
  flex-direction: column;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 36px;
    text-transform: uppercase;
  }

  p {
    font-size: 24px;
  }

}

.row.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  .container {
    margin-left: 50px;
    margin-right: 50px;
    text-align: left;
  }
}

@media only screen and (min-width: 576px) {
  // do something again
}